import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import './../style/Rekening.css'; // Import your CSS file for styles

const CopyText = ({ text }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setShowPopup(true)
      setTimeout(() => {
        setShowPopup(false);
      }, 2000);
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="copy-text-container" style={{ position: 'relative' }}>
    
      <p style={{ fontSize: "1.2em", fontWeight:"bold" }} className="font-lora ">
        {text}
        <span onClick={handleCopy} className="copy-icon">
          <FaCopy size={12} color='white'/>
        </span>
        {showPopup && (
 <FaCheck style={{marginLeft:"16px"}} color='green'/>
        )}
       
      </p>
    </div>
  );
};

export default CopyText;
