import React, { useEffect, useRef, useState } from 'react';
import './../style/Galery.css';
import {handleViewport, InjectedViewportProps }from 'react-in-viewport';

const getRandomDirection = () => {
    const directions = ['from-left', 'from-right', 'from-top', 'from-bottom'];
    return directions[Math.floor(Math.random() * directions.length)];
};


const Block = (props) => {
    const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const { inViewport, forwardedRef } = props;

  useEffect(() => {
    if (inViewport) {
        setHasBeenViewed(true);
    }
}, [inViewport]);


  return (
    <div ref={forwardedRef}>
      {hasBeenViewed &&  <Show images={props.images}/>}
      <div />
    </div>
  );
}

const Show = ({images}) => {
    const galleryRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const galleryItems = galleryRef.current.querySelectorAll('.gallery-item');
        galleryItems.forEach(item => {
            item.classList.add(getRandomDirection());
        });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                    observer.unobserve(entry.target); // Stop observing once animated
                }
            });
        }, {
            threshold: 0.1 // Trigger when at least 10% of the item is visible
        });

        galleryItems.forEach(item => {
            observer.observe(item);
        });

        return () => {
            galleryItems.forEach(item => {
                observer.unobserve(item);
            });
        };
    }, []);

    const handleImageClick = (src) => {
      setSelectedImage(src);
  };

  const closeModal = () => {
      setSelectedImage(null);
  };

    return (
      <div>
        <div className="gallery" ref={galleryRef}>
            {images.map((image, index) => (
                <div
                    key={index}
                    className="gallery-item"
                    style={{ gridColumnEnd: `span ${image.width}`, gridRowEnd: `span ${image.height}` }}
                    onClick={() => handleImageClick(image.src)}
                >
                    <img src={image.src} alt={`Gallery image ${index + 1}`} />
                </div>
            ))}
        </div>
        {selectedImage && (
                <div className="modal" onClick={closeModal}>
                    <img src={selectedImage} alt="Full screen" className="modal-image" />
                </div>
            )}
            </div>
    );
};

const Gallery = handleViewport(Block);


export default Gallery;
