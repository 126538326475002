import React, { useState, useEffect } from 'react';
import { db } from './../Firebase';
import { doc, getDocs, collection, setDoc, addDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { useForm } from 'react-hook-form';
import './../style/Admin.css';  // Assuming you create an Admin.css for your styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const AdminView = () => {
    const [attendees, setAttendees] = useState([]);
    const [summary, setSummary] = useState({ confirmed: 0, notConfirmed: 0, notAttending: 0, totalGuests: 0 });
    const { register, handleSubmit, reset } = useForm();
    const baseUrl = `${window.location.protocol}//${window.location.host}`;


    const fetchAttendees = async () => {
        const querySnapshot = await getDocs(collection(db, "attendees"));
        const attendeesData = [];
        querySnapshot.forEach((doc) => {
            attendeesData.push({ id: doc.id, ...doc.data() });
        });
        setAttendees(attendeesData);
        calculateSummary(attendeesData);
    };

    const calculateSummary = (data) => {
        let confirmed = 0, notConfirmed = 0, notAttending = 0, totalGuests = 0;
        data.forEach(attendee => {
            const attend = parseInt(attendee.attend, 10);
            const totalGuest = parseInt(attendee.totalGuest, 10);

            if (attend === 1) confirmed++;
            else if (attend === 0) notAttending++;
            else notConfirmed++;

            totalGuests += isNaN(totalGuest) ? 0 : totalGuest;
        });
        setSummary({ confirmed, notConfirmed, notAttending, totalGuests });
    };

    const onSubmit = async (data) => {
        toast.error("ANDA BUKAN ADMIN!")
    };

    const deleteAttendee = async (id) => {
        toast.error("ANDA BUKAN ADMIN!")
    };

    const updateAttendee = async (id, field, value) => {
        toast.error("ANDA BUKAN ADMIN!")
    };

    useEffect(() => {
        fetchAttendees();
    }, []);

    return (
        <div className="admin-container">
            <h2>Admin View Only</h2>
            <ToastContainer />
            <div className="summary">
                <h3>Rangkuman</h3>
                <p>Konfirm: {summary.confirmed}</p>
                <p>Belum Confirm: {summary.notConfirmed}</p>
                <p>Tidak Hadir: {summary.notAttending}</p>
                <p>Total Tamu: {summary.totalGuests}</p>
            </div>
            <div className="form-container">
                <h3>Add New Guest</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input {...register("customId")} placeholder="Link (otnielzabrina.com/guestId=<link>)" />
                    <input {...register("name", { required: true })} placeholder="Nama" />
                    <input {...register("maxAdditionalGuest", { required: true })} placeholder="Maksimal Tamu (termasuk undangan)" type="number" />
                    <button type="submit">Add Guest</button>
                </form>
            </div>
            <div className="list-container">
                <h3>Attendees List</h3>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nama</th>
                            <th>Maksimal Tamu</th>
                            <th>Total Tamu</th>
                            <th>Kehadiran</th>
                            <th>Jml Souvenier</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendees.map((attendee) => (
                            <tr key={attendee.id}>
                                <td>{attendee.id}</td>
                                <td>
                                    <input
                                        type="text"
                                        value={attendee.name}
                                        onChange={(e) => setAttendees(attendees.map(a => a.id === attendee.id ? { ...a, name: e.target.value } : a))}
                                        onKeyDown={(e) => { if (e.key === 'Enter') updateAttendee(attendee.id, "name", e.target.value); }}
                                        style={{ width: '150px' }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={attendee.maxAdditionalGuest}
                                        onChange={(e) => setAttendees(attendees.map(a => a.id === attendee.id ? { ...a, maxAdditionalGuest: e.target.value } : a))}
                                        onKeyDown={(e) => { if (e.key === 'Enter') updateAttendee(attendee.id, "maxAdditionalGuest", e.target.value); }}
                                        style={{ width: '60px' }}
                                    />
                                </td>
                                <td>{attendee.totalGuest}</td>
                                <td>{attendee.attend === '' ? 'Belum Memilih' : attendee.attend === '1' ? 'Hadir' : 'Tidak Hadir'}</td>
                                <td>
                                    {attendee.attend === '1' && (
                                        <input
                                            type="number"
                                            value={attendee.totalSouvenier}
                                            onChange={(e) => setAttendees(attendees.map(a => a.id === attendee.id ? { ...a, totalSouvenier: e.target.value } : a))}
                                            onKeyDown={(e) => { if (e.key === 'Enter') updateAttendee(attendee.id, "totalSouvenier", e.target.value); }}
                                            style={{ width: '60px' }}
                                        />
                                    )}

                                </td>

                                <td>

                                    <button onClick={() => toast.error("ANDA BUKAN ADMIN")} className='btn-blue'>Copy</button>

                                    <button className='btn-red' style={{ marginLeft: "24px" }} onClick={() => deleteAttendee(attendee.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminView;
