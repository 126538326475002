import React, { useEffect, useRef, useState } from 'react';
import './../App.css'; // Import the CSS file
import imgChiro from './../img/chiro.png'
const BackgroundMusic = React.forwardRef(({ src }) => {
  const audioRef = useRef(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showonce, setShowOnce] = useState(true);
  const buttonRef = useRef(null);

  const handlePlayPause = () => {
    
      if (isSpinning) {
        audioRef.current.pause();
        setIsSpinning(false);
      } else {
        audioRef.current.play().catch(error => {
          console.error('Play was prevented:', error);
        });
        setIsSpinning(true);
        setShowOnce(false)
      }
    
  };

  return (
    <div>
      <audio ref={audioRef} src={src} loop />
      <div className='font-tan music-button' onClick={handlePlayPause}>
      {showonce && <p style={{fontSize:'.5em', fontStyle:"bold", color:"white"}}>play chiro</p>}
      <img
        ref={buttonRef}
        className={`${isSpinning ? 'spinning' : ''}`}
        src={imgChiro}
      />
      </div>
    
    </div>
  );
});

export default BackgroundMusic;
