import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD5aHa1DzD3C7goyJLkylgo0Xy9D5KwrRE",
  authDomain: "otnielzabrina.firebaseapp.com",
  projectId: "otnielzabrina",
  storageBucket: "otnielzabrina.appspot.com",
  messagingSenderId: "576548881086",
  appId: "1:576548881086:web:936eaff3d7c8cd9638d4ef"
};

const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };