import React, { useState, useEffect } from 'react';
import './../style/GuestWish.css';
import './../App.css';
import { db } from './../Firebase';
import { collection, getDocs, addDoc } from "firebase/firestore";

const GuestWish = ({ guestName }) => {
  const [wishes, setWishes] = useState([]);
  const [wish, setWish] = useState('');

  useEffect(() => {
    fetchWishesFromBackend();
  }, []);

  const fetchWishesFromBackend = async () => {
    const wishesCollection = collection(db, 'wishes');
    const wishesSnapshot = await getDocs(wishesCollection);
    const fetchedWishes = wishesSnapshot.docs
      .map(doc => {
        const data = doc.data();
        return {
          ...data,
          date: data.date ? new Date(data.date) : new Date(0), // Use a fallback date if missing
        };
      })
      .sort((a, b) => b.date - a.date); // Sort by date in descending order
    setWishes(fetchedWishes);
  };

  const handleWishChange = (e) => {
    setWish(e.target.value);
  };

  const handleWishSubmit = async () => {
    if (wish.trim() && guestName.trim()) {
      const newWish = { 
        name: guestName, 
        wish, 
        date: new Date().toISOString() // Use ISO 8601 formatted date
      };

      // Update the backend
      await updateBackend(newWish);

      // Update the list of wishes
      setWishes([newWish, ...wishes]);
      setWish('');
    }
  };

  const updateBackend = async (newWish) => {
    const wishesCollection = collection(db, 'wishes');
    await addDoc(wishesCollection, newWish);
  };
  return (
    <div className="header-container guest-wish-containe">
       <h1 className={`font-amsterdam`} style={{ alignSelf: "center",  fontSize: "1.2em" }}>Wishes</h1>
      <textarea
      className='input-pesan'
        value={wish}
        onChange={handleWishChange}
        placeholder="Write a message"
      />
      <button className='btn-custom' onClick={handleWishSubmit}>Send</button>
      <div className="wish-list">
        {wishes.map((item, index) => (
          <div className="wish-item" key={index}>
            <p className='font-lora'>{item.name}</p>
            <p className='font-lora' style={{ fontSize:"1.2em", whiteSpace: 'pre-wrap' }}>{item.wish}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuestWish;
