import React from 'react';
import './../App.css';

const RoundImage = ({ src, alt }) => {
  return (
    <div className="round-image-container">
      <img src={src} alt={alt} className="round-image" />
    </div>
  );
};

export default RoundImage;
