import React, { useState, useEffect } from 'react';
import './../style/CountdownTimer.css'; // Import the CSS file for styling

const CountdownTimer = (className) => {
  const targetDate = new Date('2024-09-07T15:30:00+07:00');

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <div className={`countdown ${className}`}>
      <div className="time-box">
        <div className="time-unit">
          <span>{String(timeLeft.days).padStart(2, '0').slice(0, 1)}</span>
          <span>{String(timeLeft.days).padStart(2, '0').slice(1, 2)}</span>
        </div>
        <div className="label">Days</div>
      </div>
      <div className="time-box">
        <div className="time-unit">
          <span>{String(timeLeft.hours).padStart(2, '0').slice(0, 1)}</span>
          <span>{String(timeLeft.hours).padStart(2, '0').slice(1, 2)}</span>
        </div>
        <div className="label">Hours</div>
      </div>
      <div className="time-box">
        <div className="time-unit">
          <span>{String(timeLeft.minutes).padStart(2, '0').slice(0, 1)}</span>
          <span>{String(timeLeft.minutes).padStart(2, '0').slice(1, 2)}</span>
        </div>
        <div className="label">Minutes</div>
      </div>
      <div className="time-box">
        <div className="time-unit">
          <span>{String(timeLeft.seconds).padStart(2, '0').slice(0, 1)}</span>
          <span>{String(timeLeft.seconds).padStart(2, '0').slice(1, 2)}</span>
        </div>
        <div className="label">Seconds</div>
      </div>
    </div>
  );
};

export default CountdownTimer;
