import React, { useState, useEffect } from 'react';
import { handleViewport, InjectedViewportProps } from 'react-in-viewport';
import RoundImage from './RoundImage';
import OtnielImage from './../img/ic_niel.jpg';
import { FaInstagram } from 'react-icons/fa';

import ZabrinaImage from './../img/ic_zabrina.jpeg';
import './../App.css';
import { InView } from 'react-intersection-observer';


const Info = () => {
    return (
        <InView triggerOnce='true'>
            {({ inView, ref, entry }) => (
                <div ref={ref}>
                    {inView && (
                         <div id='sectionWord' className={`header-container container-word fade-in`}>
                         <InView triggerOnce='true'>
                             {({ inView, ref, entry }) => (
                                 <div ref={ref}>
                                     {inView && (
                                         <div className={`slideInFromBottom inner-content-container`} >
                                             <h1 className={`font-amsterdam`} style={{ alignSelf: "center", fontSize: "1.2em" }}>The Wedding of</h1>
                 
                                             <div style={{ marginTop: "2em" }} />
                                             <RoundImage className="fade-in" src={OtnielImage} />
                                             <p className={`font-tan fade-in`} style={{ fontSize: '1em', textAlign: "center", margin: '10px', fontWeight: "bold" }}>Otniel Victory Febe Anwar</p>
                                             <p className={`font-lora fade-in`} style={{ fontSize: '0.9em', marginLeft: '10px', marginRight: '10px', textAlign: "center" }}>The son of Mr. Lukas Tri Anwar and<br /> Mrs. Sriati Muso </p>
                                             <span className={`font-lora fade-in`} style={{ display: "flex", fontSize: '0.8em', margin: '10px', alignItems: 'center' }}> <FaInstagram /> nilevia</span>
                 
                                         </div>
                                     )
                                     }
                                 </div>
                             )}
                         </InView>
                         <div style={{ marginTop: "2.5em" }} />
                         <InView triggerOnce='true'>
                             {({ inView, ref, entry }) => (
                                 <div ref={ref}>
                                     {inView && (
                                         <div className={`slideInFromBottom inner-content-container`} >
                 
                                             <RoundImage  className="fade-in" src={ZabrinaImage} />
                                             <p className={`font-tan fade-in`} style={{ fontSize: '1em', textAlign: "center", fontWeight: "bold", margin: '10px' }}> Theresia Zabrina Budisuwita</p>
                                             <p className={`font-lora fade-in`} style={{ fontSize: '0.9em', marginLeft: '10px', marginRight: '10px', textAlign: "center" }}>The daughter of Mr. Antonius Aditya Budisuwita and<br />Mrs. Visensa Gerosa Susy Kartika</p>
                 
                                             <span className={`font-lora fade-in`} style={{ display: "flex", fontSize: '0.8em', margin: '10px', alignItems: 'center' }}> <FaInstagram /> thezaab</span>
                                         </div>
                                     )
                                     }
                                 </div>
                             )}
                         </InView>
                         </div>

)
}
</div>
)}
</InView>

       

    );
};


export default Info;
