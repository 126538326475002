import React, { useState, useRef, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Admin from './admin/Admin';
import BarcodeScanner from './welcome/Welcome';
import Guestbook from './guest/Guest';
import AdminView from './admin/AdminViewOnly';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/chirokochengimoetzekali' element={<Admin/>} />
        <Route path='/welcome' element={<BarcodeScanner/>} />
        <Route path="/guest" element={<Guestbook />} />
        <Route path="/adminview" element={<AdminView />} />
      </Routes>
    </Router>
  );
};

export default App;
