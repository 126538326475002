import React, { useState, useRef, useEffect, useCallback } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import './../App.css';
import { db } from './../Firebase';
import { doc, getDoc, updateDoc, collection, addDoc, setDoc } from "firebase/firestore";
import Cards from '../components/Cards';
import { ToastContainer, toast } from 'react-toastify';

const BarcodeScanner = () => {
    const noData = ""
    const [guestId, setGuestId] = useState(noData);
    const [lastScanned, setLastScanned] = useState(null);
    const webcamRef = useRef(null);
    const defaultGuest = {
        uniqueId: '',
        name: '',
        maxAdditionalGuest: '0',
        attend: null,
        additionalGuest: '0'
    };
    const [guestDetail, setGuestDetail] = useState(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [updateCount, setUpdateCount] = useState(null)
    const [currentDateId, setCurrentDateId] = useState()


    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                const img = new Image();
                img.src = imageSrc;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const imageData = ctx.getImageData(0, 0, img.width, img.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        setGuestId(code.data);
                        //setLastScanned(code.data);
                    }
                };
            }
        }
    }, [lastScanned]);

    useEffect(() => {
        const fetchGuest = async () => {
            try {
                const guestDocRef = doc(collection(db, 'attendees'), guestId);
                const docSnap = await getDoc(guestDocRef);
                if (docSnap.exists()) {
                    const data = docSnap.data()
                    setGuestDetail(docSnap.data());
                    updateGuestBook(data)

                } else {
                    errorLog("doc not exist");
                }
            } catch (error) {
                errorLog(error);
            }
        };

        if (guestId) {
            fetchGuest();
        }
    }, [guestId]);

    const updateGuestBook = async(guestData) =>{
        try{
            const date = getCurrentTime()
            const {name, totalGuest, maxAdditionalGuest, totalSouvenier} = guestData
            const newDoc = {
                guestId,
                name,
                maxAdditionalGuest,
                totalGuest,
                date,
                totalSouvenier: totalSouvenier || "0"
            };
            await setDoc(doc(db, "guestbook", date), newDoc);
            setCurrentDateId(date)
        } catch(error){
            errorLog(error);
        }
        
        
    }

    const getCurrentTime = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = String(now.getFullYear()).slice(-4); // Last two digits of the year
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    }

    const errorLog = async (log) => {
        try {
            const errorLog = {
                error: log,
                timestamp: new Date().toISOString()
            };

            await setDoc(doc(db, "error", guestId), errorLog);


        } catch (error) {
            console.log(error)
        }

    }


    useEffect(() => {
        const interval = setInterval(() => {
            capture();
        }, 1000); // Scans every 1 second

        return () => clearInterval(interval);
    }, [capture]);

    useEffect(() => {
        if (guestId !== noData) {
            const timer = setTimeout(() => {
                setGuestId(noData);
            }, 5000); // Show result for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [guestId]);


    const handleDropdownChange = (event) => {
        updateGuestCount(event.target.value)

    };


    const updateGuestCount = async (newGuest) => {
        setUpdateCount(newGuest);
        try {
            const guestDocRef = doc(db, 'guestbook', currentDateId);
            await updateDoc(guestDocRef, {
                realCome: newGuest
            });
            toast.success("Update Tamu Berhasil");
            setIsDropdownVisible(false)
        } catch (error) {
            errorLog(error);
            toast.error("Update Tamu Gagal");
        }
    };

    return (
        <div style={{ display: 'flex', height: '100vh', textAlign: 'center', backgroundColor: '#fff' }}>
            <ToastContainer />
            <div style={{ flex: '0 0 30%', padding: '10px' }}>
                <p style={{ fontSize: "1.3em", marginTop: "3em" }} className='font-lora'>Scan Kode Undangan</p>
                <br />
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    height="auto"
                />
            </div>
            <div style={{
                width: '1px',
                backgroundColor: '#333',
                height: '100%',
            }}></div>
            <div className='container-qr-result' style={{ flex: '1', padding: '20px' }}>
                <p className='font-lora font-shadow' style={{ fontSize: "1.3em" }}>Welcome to </p>
                <h1>Otniel & Zabrina Wedding</h1>

                {
                    guestDetail && (
                        <div className='card-qr' style={{marginTop:"40px"}}>


                            <p style={{ fontSize: "2em" }} className='font-lora'>{guestDetail.name}</p>

                            <p style={{ fontSize: "1em", marginTop: "3em" }} className='font-lora'>Jumlah Tamu</p>

                            <p style={{ fontSize: "2em", }} className='font-lora'>{guestDetail.totalGuest}</p>
                            <br />
                            <button onClick={() => setIsDropdownVisible(true)} className='btn btn-custom'>Ubah Jumlah Tamu</button>
                            {isDropdownVisible && (
                                <div style={{ marginTop: "20px" }}>
                                    <label className='font-lora' style={{ fontSize: "0.9em" }}>
                                        <select value={guestDetail.totalGuest} onChange={handleDropdownChange} style={{ marginLeft: "10px" }}>
                                            <option value="0">-</option>
                                            {Array.from({ length: 10 }, (_, i) => (
                                                <option key={i + 1} value={i + 1}>{i + 1}</option>
                                            ))}
                                        </select>
                                    </label>
                                </div>
                            )}
                            
                            {updateCount && !isDropdownVisible && (
                                <p style={{ fontSize: "1.5em", marginTop:"1em" }} className='font-lora'>Jumlah Tamu Baru {updateCount}</p>
                            )}
                        </div>

                    )
                }



            </div>
        </div>
    );
};

export default BarcodeScanner;
