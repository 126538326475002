import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import './App.css';
import Cards from './components/Cards';
import BackgroundMusic from './components/MusicPlayer';
import CopyText from './components/Rekening';
import { FaHome } from 'react-icons/fa';
import imgCiro from './img/chiro.png'
import { InView } from 'react-intersection-observer';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Gallery from './components/Galery';
import Info from './components/Info';
import GuestWish from './components/GuestWish';
import { db } from './Firebase';
import { doc, getDoc, updateDoc, collection, addDoc, setDoc } from "firebase/firestore";
import QRCode from 'qrcode.react';
import Venue from './components/Venue';
import { saveId, getIds, getErrorCount, setErrorCount } from './util/Util';

// galery
import pwd9 from './img/galery/pwd_9.jpg';
import pwd2 from './img/galery/pwd_2.jpg';
import pwd1 from './img/galery/pwd_1.jpg';
import pwd5 from './img/galery/pwd_5.jpg';
import pwd8 from './img/galery/pwd_8.jpg';
import pwd13 from './img/galery/pwd_13.jpg';
import pwd10 from './img/galery/pwd_10.jpg';
import chiro1 from './img/galery/img_chiro1.jpeg';
import chiro2 from './img/galery/img_chiro2.jpeg';
import chiro3 from './img/galery/img_chiro3.jpeg';

const imagesGalery = [
    { src: pwd9, width: 1, height: 1 },
    { src: pwd2, width: 2, height: 2 },
    { src: pwd1, width: 1, height: 1 },
    { src: pwd10, width: 2, height: 2 },
    { src: chiro2, width: 1, height: 2 },
    { src: pwd5, width: 3, height: 1 },
    { src: chiro1, width: 1, height: 2 },
    { src: pwd13, width: 1, height: 1 },
    { src: chiro3, width: 1, height: 2 },
    { src: pwd8, width: 1, height: 1 },
];

const preloadImages = (imageArray, callback) => {
    let loadedCount = 0;
    const total = imageArray.length;

    imageArray.forEach(image => {
        const img = new Image();
        img.src = image.src;
        img.onload = () => {
            loadedCount++;
            if (loadedCount === total) {
                callback();
            }
        };
        img.onerror = () => {
            // Handle error if needed
            loadedCount++;
            if (loadedCount === total) {
                callback();
            }
        };
    });
};

//

function Home() {

    const [isLoading, setIsLoading] = useState(true);
    const [preloadedImages, setPreloadedImages] = useState([]);

    useEffect(() => {
        const MINIMUM_LOADING_TIME = 2000; // Minimum loading time in milliseconds
        const startLoadingTime = Date.now();

        preloadImages(imagesGalery, () => {
            const timeElapsed = Date.now() - startLoadingTime;
            const remainingTime = MINIMUM_LOADING_TIME - timeElapsed;

            if (remainingTime > 0) {
                setTimeout(() => {
                    setPreloadedImages(imagesGalery);
                    setIsLoading(false);
                    document.body.classList.add('app-loaded');
                }, remainingTime);
            } else {
                setPreloadedImages(imagesGalery);
                setIsLoading(false);
                document.body.classList.add('app-loaded');
            }
        });
    }, []);


    const [isSectionConfirmVisible, setIsSectionConfirmVisible] = useState(false);
    const [isSectionGiftVisible, setIsSectionGiftVisible] = useState(false);
    const [isMusicPlaying, setIsMusicPlaying] = useState(false);
    const [buttonText, setButtonText] = useState('Unable');
    const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
    const [clickCount, setClickCount] = useState(0);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [numPeople, setNumPeople] = useState(0);
    const sectionConfirmRef = useRef(null);
    const sectionGiftRef = useRef(null);
    const sectionWordRef = useRef(null);
    const musicRef = useRef(null);
    const [textHadir, setTextHadir] = useState("");
    const [maxGuest, setMaxGuest] = useState(2)

    const [invalidGuest, setInvalidGuest] = useState(false);

    const [isSuspect, setIsSuspect] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guestId = queryParams.get('id') || null;
    const defaultGuest = {
        uniqueId: '',
        name: '-',
        maxAdditionalGuest: '1',
        attend: null,
        additionalGuest: '0'
    };
    const [guestDetail, setGuestDetail] = useState();

    useEffect(() => {
        const fetchGuest = async () => {
            console.log("fetch guest")
            try {
                const guestDocRef = doc(collection(db, 'attendees'), guestId);
                const docSnap = await getDoc(guestDocRef);
                if (docSnap.exists()) {
                    const data = docSnap.data()
                    setGuestDetail(docSnap.data());

                    if (data.attend == "0") setTextHadir("You cannot attend. Thank you\n(Anda memilih tidak hadir)")
                    else if (data.attend == "1") {
                        const hadir = data.totalGuest === 1 ? "sendiri" : parseInt(data.totalGuest);
                        if (hadir == 0) {
                            setTextHadir("Kindly choose the number of people, including yourself.");

                        } else {
                            setTextHadir(`You're attending with a total of ${hadir} guests. See you soon 🤍`)

                        }
                        setIsDropdownVisible(true);
                        setNumPeople(hadir)
                        setShowBarcode(true)
                    } else {
                        setTextHadir("")
                        setShowBarcode(false)
                    }

                    setMaxGuest(data.maxAdditionalGuest)

                } else {
                    setErrorCount()
                    errorLog("doc not exist");
                    setInvalidGuest(true)
                    setIsLoading(false)
                }
            } catch (error) {
                setInvalidGuest(true)
                errorLog(error);
            }
        };

        if (guestId) {
            saveId(guestId)
            if (getErrorCount() > 3) {
                setIsLoading(false)
                setIsSuspect(true)
            }
            else {
                fetchGuest();
            }
        } else {
            setIsLoading(false)
        }
    }, [guestId]);

    const updateGuest = async (attend, totalGuest) => {
        try {
            const guestDocRef = doc(db, 'attendees', guestId);
            await updateDoc(guestDocRef, {
                attend: attend,
                totalGuest: totalGuest
            });

            if (attend == "1") {
                setShowBarcode(true)
            }
            else setShowBarcode(false)
        } catch (error) {
            errorLog(error);
        }
    };


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsSectionConfirmVisible(true);
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionConfirmRef.current) {
            observer.observe(sectionConfirmRef.current);
        }

        return () => {
            if (sectionConfirmRef.current) {
                observer.unobserve(sectionConfirmRef.current);
            }
        };
    }, []);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsSectionGiftVisible(true);
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (sectionGiftRef.current) {
            observer.observe(sectionGiftRef.current);
        }

        return () => {
            if (sectionGiftRef.current) {
                observer.unobserve(sectionGiftRef.current);
            }
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            if (!isMusicPlaying) {
                setIsMusicPlaying(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMusicPlaying]);

    const scrollToSection = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    };

    const handleButtonTidakHadirClick = () => {
        setTextHadir("You cannot attend. Thank you\n(Anda memilih tidak hadir)");
        setIsDropdownVisible(false);
        updateGuest("0", "0")
        setClickCount(prevCount => prevCount + 1);
    };

    const handleButtonHadirClick = () => {
        setIsDropdownVisible(true);
        setTextHadir("Kindly choose the number of people, including yourself.");
        setNumPeople(0);
        updateGuest("1", "0")

    };

    const handleDropdownChange = (event) => {
        setNumPeople(event.target.value);
        if (event.target.value == 0) {
            setTextHadir("Kindly choose the number of people, including yourself.");
        } else if (event.target.value == 1) {
            setTextHadir(`You're attending alone. See you soon 🤍`);
            setIsDropdownVisible(true);
        } else {
            setTextHadir(`You're attending with a total of ${event.target.value} guests. See you soon 🤍`);
            setIsDropdownVisible(true);
        }
        updateGuest("1", event.target.value)

    };


    const errorLog = async (log) => {
        try {
            const date = new Date().toISOString()
            const errorLog = {
                error: log,
                timestamp: date,
                ids: getIds(),
                errorCount: getErrorCount()
            };

            await setDoc(doc(db, "error", date + guestId), errorLog);


        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div style={{ marginBottom: "40px" }}>
            {invalidGuest && (
                <div className="modal-block" >
                    <Cards width="600px" className='black-card'>
                        <h1 className="font-tan" style={{ marginTop: "0px", alignSelf: "center", lineHeight: "20px", fontSize: "1em" }}>Daftar Tamu Tidak Ditemukan</h1>
                        <h1 className="font-tan" style={{ marginTop: "40px", alignSelf: "center", lineHeight: "20px", fontSize: "0.8em" }}>Silahkan periksa link dengan benar</h1>

                    </Cards>
                    {/* <img src={ImgStatic} alt="Full screen" className="modal-block-image" /> */}
                </div>
            )}

            {isSuspect && (
                <div className="modal-block" >
                    <Cards width="600px" className='black-card'>
                        <h1 className="font-tan" style={{ marginTop: "0px", alignSelf: "center", lineHeight: "20px", fontSize: "1em" }}>Anda mengalami error terlalu banyak</h1>
                        <h1 className="font-tan" style={{ marginTop: "40px", alignSelf: "center", lineHeight: "20px", fontSize: "0.8em" }}>Silahkan hubungi pengantin untuk memeriksa daftar tamu</h1>

                    </Cards>
                    {/* <img src={ImgStatic} alt="Full screen" className="modal-block-image" /> */}
                </div>
            )}

            <div>
                {isLoading ? (
                    <div className="modal-block" >
                        <Cards width="600px" className='black-card'>
                            <h1 className="font-tan" style={{ marginTop: "0px", alignSelf: "center", lineHeight: "20px", fontSize: "0.8em" }}>Loading</h1>
                            <br />
                            <div className="circle-loader">
                                <img src={imgCiro} className="circle" />
                                <img src={imgCiro} className="circle" />
                                <img src={imgCiro} className="circle" />
                                <img src={imgCiro} className="circle" />
                                <img src={imgCiro} className="circle" />
                            </div>
                        </Cards>
                    </div>
                ) : (

                    <div>
                        {guestDetail&& (


                            <div className='main-content' style={{ maxWidth: "600px", alignItems: "center", margin: "0 auto" }} >
                                <BackgroundMusic ref={musicRef} src="/music/mbak-taylor.mp3" isPlaying={isMusicPlaying} />
                                <header id='section1' className="header-container" style={{ marginBottom: "20px", height: "100vh" }}>
                                    <p className='maincolor font-lora' style={{ marginBottom: "40px" }}>WEDDING INVITATION</p>
                                    <h1 className="anim-atas maincolor" style={{ lineHeight: "70px" }}>Otniel</h1>
                                    <h1 className="anim-atas maincolor" style={{ lineHeight: "70px" }}>&</h1>
                                    <h1 className="anim-atas maincolor" style={{ lineHeight: "70px" }}>Zabrina</h1>
                                    <p className='maincolor font-lora' style={{ fontSize: "0.7em", marginTop: "300px" }}>With Love and Joy, We Invite:</p>
                                    <p className='font-lora maincolor' style={{ fontSize: "0.8em" }}>{guestDetail.name}</p>
                                    <span className="chevron-down" onClick={() => scrollToSection("sectionWord")}>
                                        <FaChevronDown />
                                    </span>
                                </header>

                                <div id="sectionWord">
                                    <Info />
                                </div>

                                <div >
                                    <Venue />
                                </div>

                                {/* <InView triggerOnce='true'>
    {({ inView, ref, entry }) => (
        <div ref={ref}>
            {inView && (

                 )
            }
        </div>
    )}
</InView> */}

                                <InView triggerOnce='true'>
                                    {({ inView, ref, entry }) => (
                                        <div ref={ref}>
                                            {inView && (
                                                <div id='section3' className={`header-container-half container-confirm slideInFromBottom`}>

                                                    <h1 className={`font-amsterdam`} style={{ alignSelf: "center", fontSize: "1.2em", marginBottom: "1em" }}>Confirmation</h1>

                                                    <Cards width="400px" className={`${isSectionConfirmVisible ? 'slideInFromBottom' : ''} black-card`}>
                                                        <p className='font-lora' style={{ fontSize: "1em" }}>Kindly confirm your attendance at our wedding by clicking this button.</p>

                                                        <span>
                                                            <button onClick={handleButtonHadirClick} className={`btn btn-custom `} style={{ marginTop: "20px", marginRight: "10px", width: "120px" }}>Attend</button>

                                                            <button
                                                                id='btn-tidak-hadir'
                                                                className={`btn-second btn-custom `}
                                                                style={{ marginTop: "20px", marginLeft: "10px", width: "120px", position: 'relative', top: `${buttonPosition.top}px`, left: `${buttonPosition.left}px` }}
                                                                onClick={handleButtonTidakHadirClick}
                                                            >
                                                                {buttonText}
                                                            </button>
                                                        </span>

                                                        {isDropdownVisible && (
                                                            <div style={{ marginTop: "20px" }}>
                                                                <label className='font-lora' style={{ fontSize: "0.9em" }}>
                                                                    Number of Guest:
                                                                    <select value={numPeople} onChange={handleDropdownChange} style={{ marginLeft: "10px" }}>
                                                                        <option value="0">-</option>
                                                                        {Array.from({ length: maxGuest }, (_, i) => (
                                                                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                                        ))}
                                                                    </select>
                                                                </label>
                                                            </div>
                                                        )}

                                                        <span className='font-lora' style={{ textAlign: "center", marginTop: "16px", fontSize: "0.8em" }}>{textHadir}</span>
                                                        {showBarcode && (
                                                            <div style={{ marginTop: "16px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <QRCode
                                                                    value={guestId}
                                                                    size={200}
                                                                    bgColor="#ffffff"
                                                                    fgColor="#000000"
                                                                    level="L"
                                                                    includeMargin={true}
                                                                    renderAs="svg"
                                                                    className="qr-code" >

                                                                    <img
                                                                        href={imgCiro}
                                                                        x="50%"
                                                                        y="50%"
                                                                        width="50"
                                                                        height="50"
                                                                        transform="translate(-25, -25)"
                                                                    />
                                                                </QRCode>
                                                                <p className='font-lora' style={{ textAlign: "center", marginTop: "16px", fontSize: "0.8em" }}>*Will all due respect, please arrive on time because of the intimate nature of the ceremony</p>

                                                            </div>
                                                        )}
                                                    </Cards>

                                                    <br />
                                                </div>
                                            )
                                            }
                                        </div>
                                    )}
                                </InView>


                                <br />


                                <InView triggerOnce='true'>
                                    {({ inView, ref, entry }) => (
                                        <div ref={ref}>
                                            {inView && (
                                                <div id='section4' style={{ paddingLeft: '16px', paddingRight: '16px' }} className={`header-container slideInFromBottom`}>

                                                    <Cards width="400px" className={`gift-card`}>
                                                        <h1 className={`font-amsterdam`} style={{ alignSelf: "center", fontSize: "1.2em", marginBottom: '16px' }}>Wedding Gift</h1>
                                                        <p className='font-lora' style={{ fontSize: "0.8em" }}>Your presence is a present in itself</p>
                                                        <p className='font-lora' style={{ fontSize: "0.8em", marginBottom: "30px" }}>But if you do wish to give us something else,</p>



                                                        <CopyText text="3151131512" />
                                                        <p className='font-lora' style={{ fontSize: "1em", marginBottom: "64px" }}>BCA - Theresia Zabrina Budisuwita</p>

                                                        <CopyText text="3850624878" />
                                                        <p className='font-lora' style={{ fontSize: "1em", marginBottom: "64px" }}>BCA - Otniel Victory F A</p>



                                                        <p className='font-lora' style={{ fontSize: "1em", fontWeight: 'bold', textAlign: "center", display: "flex" }}>
                                                            <span className="copy-icon" style={{ marginRight: '12px' }}> <FaHome size={20} color='white' /></span>
                                                            Gift Box
                                                        </p>

                                                        <p className='font-lora' style={{ fontSize: "1em" }}>Perumahan The 9 Residence Kav 94, Jalan Arumba,
                                                            Lowokwaru Kota Malang, 65143</p>

                                                    </Cards>

                                                </div>
                                            )
                                            }
                                        </div>
                                    )}
                                </InView>


                                <div className='header-container container-galery fade-in' style={{ paddingLeft: "6px", paddingRight: '6px' }}>
                                    <h1 className={`font-amsterdam`} style={{ alignSelf: "center", fontSize: "1.2em", marginBottom: '30px' }}>Gallery</h1>
                                    <Gallery images={preloadedImages} />
                                </div>

                                <div>
                                    <GuestWish guestName={guestDetail.name} />
                                </div>
                            </div>
                        )}
                    </div>

                )}
            </div>

        </div>
    );
}

export default Home;
