import React, { useState, useEffect, useRef } from 'react';
import { handleViewport, InjectedViewportProps } from 'react-in-viewport';
import Cards from './Cards';
import OpenMapsButton from './OpenMaps';
import CountdownTimer from './CountdownTimer';
import './../App.css';
import { InView } from 'react-intersection-observer';

const Venue = () => {
    return (
        <div id='sectionInfo' className="header-container container-info" >
            <InView triggerOnce='true'>
                {({ inView, ref, entry }) => (
                    <div ref={ref}>
                        {inView && (
                            <div>
                                <Cards width="300px" className="card-dark slideInFromRight">
                                    <h1 className="font-amsterdam" style={{ marginTop: "z0px", alignSelf: "center", fontSize: "1.2em" }}>Holy Matrimony</h1>
                                    <p className={`font-lora`} style={{ fontSize: "1em", marginTop: "20px" }}>Saturday, 07 September 2024</p>
                                    <p className={`font-lora`} style={{ fontSize: "1em" }}>09:00 - 11:00 WIB</p>
                                    <br />
                                    <p className={`font-lora`} style={{ fontSize: ".8em" }}>Gereja Katolik Hati Kudus Yesus Kayutangan.<br />Kota Malang</p>
                                    <OpenMapsButton className={`btn btn-custom`} link="https://maps.app.goo.gl/TkXY8hmgdBrY59J66" />
                                </Cards>

                            </div>
                        )}
                    </div>
                )}
            </InView>

            <div style={{ marginTop: "2em" }} />

            <InView triggerOnce='true'>
                {({ inView, ref, entry }) => (
                    <div ref={ref}>
                        {inView && (
                            <div>
                               
                                <Cards width="300px" className="card-dark slideInFromRight">
                                    <h1 className="font-amsterdam" style={{ marginTop: "z0px", alignSelf: "center", fontSize: "1.2em" }}>Reception</h1>
                                    <p className={`font-lora`} style={{ fontSize: "1em", marginTop: "20px" }}>Saturday, 07 September 2024</p>
                                    <p className={`font-lora`} style={{ fontSize: "1em" }}>15:30 - 18.00 WIB</p>
                                    <br />
                                    <p className={`font-lora`} style={{ fontSize: ".8em" }}>Hotel Santika Premier<br />Kota Malang</p>
                                    <OpenMapsButton className={`btn btn-custom`} link="https://maps.app.goo.gl/DEHoovsxExNa2hH68" />
                                </Cards>
                            </div>
                        )}
                    </div>
                )}
            </InView>

            <InView triggerOnce='true'>
                {({ inView, ref, entry }) => (
                    <div ref={ref}>
                        {inView && (

            <div className='font-lora slideInFromRight'>
                <CountdownTimer />


            </div>

)}
</div>
)}
</InView>

        </div>
    );
};

export default Venue;
