import React from 'react';

const OpenMapsButton = ({link, className}) => {
  const openGoogleMaps = () => {
    //const address = '1600 Amphitheatre Parkway, Mountain View, CA'; // Replace with the desired address
    const googleMapsUrl = link;
    window.open(googleMapsUrl, '_blank'); // Opens in a new tab
  };

  return (
    <button style={{ marginTop: "20px" }} className={className} onClick={openGoogleMaps}>
      Open Maps
    </button>
  );
};

export default OpenMapsButton;
