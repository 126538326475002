import React, { useState, useEffect } from 'react';
import { db } from './../Firebase';
import { collection, query, orderBy, onSnapshot, addDoc } from 'firebase/firestore';
import './../App.css';
import { ToastContainer, toast } from 'react-toastify';

const Guestbook = () => {
    const [guests, setGuests] = useState([]);

    useEffect(() => {
        const q = query(collection(db, 'guestbook'), orderBy('date'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const guestsData = [];
            querySnapshot.forEach((doc) => {
                guestsData.push({ id: doc.id, ...doc.data() });
            });
            setGuests(guestsData);
        });

        return () => unsubscribe(); // Cleanup the listener on component unmount
    }, []);


    return (
        <div className="admin-container" style={{marginTop: "40px"}}>
            <h2>Guest Page</h2>
            <ToastContainer />

            <div className="list-container">
                <h3>Guest List</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Waktu</th>
                            <th>Nama</th>
                            <th>Jumlah Tamu Tercatat</th>
                            <th>Jumlah Souvenier</th>
                            <th>Jumlah Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guests.map((guest) => (
                            <tr key={guest.date}>
                                <td>{guest.date}</td>
                                <td>{guest.name}</td>
                                <td>{guest.totalGuest}</td>
                                <td>{guest.totalSouvenier}</td>
                                <td>{guest.realCome||"-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Guestbook;
