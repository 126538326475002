
export const saveId=(id) => {
    const listId = getIds()
    listId.push(id)
    localStorage.setItem('iDs', JSON.stringify(listId));
}

export const getIds = () => {
    return JSON.parse(localStorage.getItem('iDs')) || [];
}

export const setErrorCount = () => {
    const totalError =  JSON.parse(localStorage.getItem('errorCount')) || 0;
    localStorage.setItem('errorCount', JSON.stringify(totalError+1));


}

export const getErrorCount = () => {
    const count =  JSON.parse(localStorage.getItem('errorCount')) || 0;
    return parseInt(count)

}